<template>
  <base-form-item :field="field">
    <!--        <div class="position-relative">-->
    <!--            <span @click="toogleVisibility">-->
    <!--                <svg-icon style="cursor: pointer" :icon-class="show ? 'minus' : 'plus'"/>-->
    <!--            </span>-->
    <!--        </div>-->
    <el-input
        :autocomplete="field.meta && field.meta.autocomplete ? field.meta.autocomplete : 'off'"
        :autofocus="field.meta && field.meta.autofocus ? field.meta.autofocus : false"
        :class="checkinputRequired(field.dbName) ? 'validate-error' : ''"
        :clearable="field.meta && field.meta.clearable ? field.meta.clearable : false"
        :disabled="field.meta && field.meta.disabled ? field.meta.disabled : false"
        :maxlength="field.meta && field.meta.max ? field.meta.max : ''"
        :minlength="field.meta && field.meta.min ? field.meta.min : ''"
        :name="field.dbName"
        :placeholder="field.meta && field.meta.placeholder ? field.meta.placeholder : ''"
        :prefix-icon="field.meta && field.meta.prefixIcon ? field.meta.prefixIcon : ''"
        :readonly="field.meta && field.meta.readonly ? field.meta.readonly : false"
        :resize="field.meta && field.meta.resize ? field.meta.resize : ''"
        :show-password="field.meta && field.meta.showPassword ? field.meta.showPassword : false"
        :size="field.meta && field.meta.size ? field.meta.size : ''"
        :step="field.meta && field.meta.step ? field.meta.step : ''"
        :suffix-icon="field.meta && field.meta.suffixIcon ? field.meta.suffixIcon : ''"
        @keyup.enter.native="addItem"
        v-model="newElement"
    >
      <i
          @click.stop="addItem"
          class="el-input__icon el-icon-plus pointer"
          slot="suffix"
      ></i>
    </el-input>
    <small
        class="help-block"
        v-if="backend_errors[field.dbName] && !checkinputRequired(field.dbName)"
    >
      <span v-html="validationTranslate(backend_errors[field.dbName][0])"></span>
    </small>
    <div v-if="data.checklist && data.checklist.length > 0">
      <draggable
          @change="endMove"
          class="el-form--todoList"
          element="ul"
          v-model="data.checklist"
      >
        <li
            :key="index"
            v-for="(item, index) in data.checklist"
        >
          <div
              class="d-flex justify-content-between align-items-center"
              v-if="item !== editedElement"
          >
            <el-checkbox
                @change="item.done =! item.done"
                aria-hidden="true"
                v-bind:checked="item.done ? true : false"
            >
              <span
                  :class="item.done ? 'strike' : ''"
                  @dblclick="editItem(item)"
              >{{ item.subject }}</span>
            </el-checkbox>
            <span>
                                <i
                                    @click="removeItem(item)"
                                    aria-hidden="true"
                                >
                                    <svg-icon icon-class="delete-button" />
                                </i>
                            </span>
          </div>
          <div v-if="item === editedElement">
            <el-input
                @blur="updateEdit(item)"
                @keyup.enter.native="updateEdit(item)"
                v-model="item.subject"
                v-on:keydown.esc.prevent.stop="cancelEdit(item)"
            >
              <i
                  @click.stop="removeItem(item)"
                  class="el-input__icon el-icon-close"
                  slot="suffix"
              ></i>
            </el-input>
          </div>
        </li>
      </draggable>
    </div>
  </base-form-item>
</template>

<script>
import swal from 'sweetalert2';
import draggable from 'vuedraggable';
import notification from '../../../../../notification/notify';
import abstractForm from '../../mixin/index';
import BaseFormItem from '../../UI/FormItem.vue';

export default {
  mixins: [abstractForm],
  name: 'CreateOrUpdate',
  components: {
    draggable, BaseFormItem,
  },
  data() {
    return {
      show: false,
      newElement: '',
      editedElement: null,
      beforeEditCache: '',
    };
  },
  methods: {
    toogleVisibility() {
      this.show = !this.show;
    },
    addItem() {
      let count = Object.keys(this.data.checklist).length;
      let newElement = this.newElement && this.newElement.trim();
      if (!newElement) {
        return;
      }
      this.data.checklist.push({
        fixed: false,
        subject: newElement,
        position: count + 1,
        done: false,
        edit: false,
        deleted_at: null,
      });
      this.value = this.data.checklist;
      this.newElement = '';
    },
    editItem(item) {
      this.show = false;
      item.edit = true;
      this.beforeEditCache = item.subject;
      this.editedElement = item;
    },
    updateEdit(item) {
      item.edit = false;
      this.editedElement = null;
      item.subject = item.subject.trim();
      if (!item.subject) {
        this.removeItem(item);
      }
    },
    removeItem(item) {
      let vm = this;
      swal.fire({
        target: vm.$refs.root,
        title: vm.$t('system.remove'),
        text: vm.$t('system.if_you_sure'),
        type: 'info',
        showCancelButton: true,
        confirmButtonText: vm.$t('system.yes'),
        cancelButtonText: vm.$t('system.no'),
        focusCancel: true,
      }).then(
          function (resp) {
            if (resp.value) {
              vm.data.checklist.splice(vm.data.checklist.indexOf(item), 1);
            }
          },
      ).catch(function (error) {
        notification.notify(
            vm.$t('notify.error'),
            error.message,
            'error');
      });
    },
    cancelEdit(item) {
      item.edit = false;
      this.editedElement = null;
      item.subject = this.beforeEditCache;
    },
    endMove(event) {
      this.data.checklist[event.moved.oldIndex].position = event.moved.oldIndex;
      this.data.checklist[event.moved.newIndex].position = event.moved.newIndex;
    },
  },
};
</script>
<style
    lang="scss"
    rel="stylesheet/scss"
>
.el-form--checklist {
  .el-form-item__content {
    display: inline-block;
    margin: 0 !important;
    width: 100%;
  }

  .el-form--todoList {
    label {
      margin: 0;
    }
  }
}
</style>
<style
    lang="scss"
    rel="stylesheet/scss"
    scoped
>
.el-form--todoList {
  margin: 0;
  list-style-type: none;
  padding: 0;

  li {
    cursor: pointer;
    @keyframes strike {
      0% {
        width: 0;
      }
      100% {
        width: 100%;
      }
    }

    .strike {
      position: relative;
    }

    .strike::after {
      content: ' ';
      position: absolute;
      top: 55%;
      left: 0;
      width: 100%;
      height: 1px;
      background: black;
      animation-name: strike;
      animation-duration: .3s;
      animation-timing-function: linear;
      animation-iteration-count: 1;
      animation-fill-mode: forwards;
    }
  }
}
</style>
